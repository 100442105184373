import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { useGetPreferredCategories } from "./hooks";

export const BecauseYouLiked1 = () => {
  const { preferredCategory1, loading } = useGetPreferredCategories();

  if (!preferredCategory1) {
    return <></>;
  }

  return (
    <Section
      title={`Because you liked ${preferredCategory1.name}`}
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.results__categories}/${preferredCategory1.slug}`,
        text: "See All",
      }}
    >
      <Slider
        data={preferredCategory1.classes}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.BecauseYouLiked}
            selectedFrom="BecauseYouLiked"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};

export const BecauseYouLiked2 = () => {
  const { preferredCategory2, loading } = useGetPreferredCategories();

  if (!preferredCategory2) {
    return <></>;
  }

  return (
    <Section
      title={`Because you liked ${preferredCategory2.name}`}
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.results__categories}/${preferredCategory2.slug}`,
        text: "See All",
      }}
    >
      <Slider
        data={preferredCategory2.classes}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.BecauseYouLiked}
            selectedFrom="BecauseYouLiked"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
